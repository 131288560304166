import { useEffect, useState } from 'react';
import { Box, Button, Flex } from 'rebass';
import {
  CustomerFeeBreakdown,
  LeftPane,
  MerchantDetails,
  MerchantLogo,
  MobileFooter,
  MobileHeader,
  MobilePaymentMethod,
  MobilePaymentMethods,
  MobilePaymentWrapper,
  NIconWrapper,
  OrderDetails,
  PaymentMethodItem,
  PaymentMethods,
  PaymentWrapper,
  RightPane,
} from './style';
import { Avatar, IconsEnum, NIcons, Typography } from 'kudi-component-library';
import TransferActiveIcon from '../../assets/transfer-active';
import AtmCardInActiveIcon from '../../assets/card-inactive';
import Transfer from './Transfer/Transfer';
import Loader from '../Loader';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useParams } from 'react-router-dom';
import { fetchBanks, getKtaDetails, getOrderDetails } from '../../redux/action';
import FailedResponse from './Responses/FailedResponse';
import ModalV2 from '../ModalV2';
import { IPaymentOptions, ScreenTypes, formatCurrency, handleCallbackUrl } from '../../utils';
import SuccessResponse from './Responses/SuccessResponse';
import Card from './Card/Card';
import TransferInActiveIcon from '../../assets/transfer-inactive';
import AtmCardActiveIcon from '../../assets/card-active';
import PhoneSuccess from './Responses/PhoneSuccess';
import { ChevronRight, Star } from '../../assets';
import { ButtonText } from '../styles';
import FooterLogoDark from '../../assets/footer-logo-dark';
import NombaQrActiveIcon from '../../assets/qr-active';
import NombaQrInActiveIcon from '../../assets/qr-inactive';
import NombaQr from './NombaQr/qr';
import UssdIcon2 from '../../assets/ussd2';
import UssdIcon from '../../assets/ussd';
import USSDSelectBank from './Ussd/USSDSelectBank';
import CloseModalNew from '../../assets/closemodal-new';
import ConfirmModal from '../ConfirmModal';
interface IModalProps {
  showModal: boolean;
  customId?: string;
  redirectUrl?: string;
  setShowModal?: () => void;
}

const sortedList = ['Transfer', 'Card', 'Nomba QR', 'USSD'];

const initialPaymentMethodsOptions: IPaymentOptions[] = [
  {
    id: 1,
    value: 'BANK_TRANSFER',
    name: 'Transfer',
    subText: '',
    mobileName: 'Pay by Transfer',
    activeIcon: <TransferActiveIcon />,
    inActiveIcon: <TransferInActiveIcon />,
  },
  {
    id: 2,
    value: 'CARD',
    name: 'Card',
    mobileName: 'Pay by Card',
    subText: '',
    activeIcon: <AtmCardActiveIcon />,
    inActiveIcon: <AtmCardInActiveIcon />,
  },
  {
    id: 3,
    value: 'NOMBA_QR',
    name: 'Nomba QR',
    subText: 'Earn 2% cashback',
    mobileName: 'Pay with Nomba QR',
    activeIcon: <NombaQrActiveIcon />,
    inActiveIcon: <NombaQrInActiveIcon />,
  },
  {
    id: 4,
    value: 'USSD',
    name: 'USSD',
    mobileName: 'Pay with USSD',
    subText: '',
    activeIcon: <UssdIcon />,
    inActiveIcon: <UssdIcon2 />,
  },
];

export default function MainModal({ showModal, customId, redirectUrl, setShowModal }: IModalProps) {
  const [active, setActive] = useState<number>(1);
  const [showMobile, setShowMobile] = useState<boolean>(false);

  const { isLoading, orderDetails, ktaResponse, fetchBankResponse } = useSelector((state: RootState) => state.order);

  const [paymentMethodsOptions, setPaymentMethodOptions] = useState<IPaymentOptions[] | []>(
    initialPaymentMethodsOptions
  );

  const dispatch = useDispatch<AppDispatch>();

  const { id } = useParams();

  const uid = customId || id;

  const [currentScreen, setCurrentScreen] = useState<ScreenTypes>('Main');
  const [errorMsg, setErrMsg] = useState<string>('');
  const [isSaveCard, setIsSaveCard] = useState<boolean>(false);
  const [showCustomerFee, setShowCustomerFee] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  useEffect(() => {
    if (uid) {
      dispatch(getOrderDetails({ orderId: uid }));
      dispatch(getKtaDetails({ orderId: uid }));
    }

    dispatch(fetchBanks());
  }, [dispatch, uid]);

  useEffect(() => {
    if (!orderDetails || orderDetails === null) {
      return;
    }
    if (orderDetails && orderDetails?.code !== '00') {
      setErrMsg(orderDetails?.description);
      setCurrentScreen('FailedResponse');
    }

    if (orderDetails && orderDetails?.data?.allowedPaymentModes?.length) {
      const paymentMethodList = orderDetails?.data?.allowedPaymentModes;

      const allowedPaymentMethods: string[] = [...paymentMethodList]?.sort((a, b) => {
        return sortedList.indexOf(a) - sortedList.indexOf(b);
      });

      const filteredOptions = initialPaymentMethodsOptions.filter((option) => {
        return allowedPaymentMethods.includes(option.name);
      });

      filteredOptions?.length && setPaymentMethodOptions(filteredOptions);

      const activeView = initialPaymentMethodsOptions?.find((item) => item?.name === allowedPaymentMethods?.[0]);

      if (activeView) {
        setActive(activeView?.id);
      }
    }
  }, [orderDetails]);

  // const getInitials = (name: string | undefined | null) => {
  //   if (!name) return '';
  //   const words = name.split(' ');
  //   let initials = '';

  //   for (let i = 0; i < Math.min(2, words.length); i++) {
  //     initials += words[i][0].toUpperCase();
  //   }

  //   return initials;
  // };

  const getInitials = (businessName: string | undefined | null, businessEmail: string | undefined | null) => {
    const name = businessName ?? businessEmail;

    if (!name) return '';

    const letters = name.split('');
    let initials = '';

    for (let i = 0; i < 2; i++) {
      initials += letters?.[i]?.toUpperCase() || '';
    }

    return initials;
  };

  const onMoveToNewScreen = (newscreen: ScreenTypes, error: string, saveCard: boolean) => {
    setCurrentScreen(newscreen);
    if (error !== '') {
      setErrMsg(error);
    }
    if (saveCard) {
      setIsSaveCard(saveCard);
    }
  };

  const changePaymentMethod = () => {
    setShowMobile(false);
  };

  const cancel = () => {
    if (customId && setShowModal) {
      setShowModal();
      return;
    }
    if (orderDetails) {
      handleCallbackUrl(
        orderDetails?.data?.order?.callbackUrl,
        orderDetails?.data?.order?.orderId,
        orderDetails?.data?.order?.orderReference
      );
    }
  };

  const confirm = () => {
    if (customId && setShowModal) {
      setShowModal();
      return;
    }
    if (orderDetails) {
      handleCallbackUrl(
        orderDetails?.data?.order?.callbackUrl,
        orderDetails?.data?.order.orderId,
        orderDetails?.data?.order?.orderReference
      );
    }
  };

  const handleClose = () => {
    setShowConfirmModal(false);
  };

  return (
    <>
      {currentScreen === 'Main' && (
        <ModalV2
          showModal={showModal}
          onClose={() => {
            if (customId && setShowModal) {
              setShowModal();
              return;
            }
            window.close();
          }}
        >
          {isLoading ? (
            <Flex
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              mt="auto"
              mb="auto"
              style={{ height: '100%' }}
            >
              <Loader />
            </Flex>
          ) : (
            <>
              <PaymentWrapper>
                <LeftPane>
                  <MerchantDetails>
                    <Flex alignItems="center" style={{ gap: '10px' }}>
                      <MerchantLogo>
                        {orderDetails?.data?.order?.businessLogo ? (
                          <img
                            alt="business"
                            style={{
                              width: '100%',
                              height: '100%',
                              borderRadius: '50%',
                              objectFit: 'cover',
                            }}
                            src={orderDetails?.data?.order?.businessLogo}
                          />
                        ) : (
                          <Avatar
                            size="medium"
                            initials={getInitials(
                              orderDetails?.data?.order?.businessName,
                              orderDetails?.data?.order?.businessEmail
                            )}
                            imgUrl={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                              orderDetails?.data?.order?.businessName ??
                                orderDetails?.data?.order?.businessEmail ??
                                'Unknown'
                            )}&background=FFF5CC&color=665200&rounded=true`}
                          />
                        )}
                      </MerchantLogo>
                      <Flex flexDirection="column">
                        <Typography fontSize="10px">You are paying</Typography>
                        <Typography fontSize="12px" fontWeight={700} className="ellipsis">
                          {orderDetails?.data?.order?.businessName}
                        </Typography>
                      </Flex>
                    </Flex>
                  </MerchantDetails>

                  <MobileHeader>
                    <MerchantLogo>
                      {orderDetails?.data?.order?.businessLogo ? (
                        <img
                          alt="business"
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            objectFit: 'cover',
                          }}
                          src={orderDetails?.data?.order?.businessLogo}
                        />
                      ) : (
                        <Avatar
                          size="medium"
                          initials={getInitials(
                            orderDetails?.data?.order?.businessName,
                            orderDetails?.data?.order?.businessEmail
                          )}
                          imgUrl={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                            orderDetails?.data?.order?.businessName ??
                              orderDetails?.data?.order?.businessEmail ??
                              'Unknown'
                          )}&background=FFF5CC&color=665200&rounded=true`}
                        />
                      )}
                    </MerchantLogo>
                    <Flex flexDirection="column">
                      <Typography fontSize="18px" fontWeight={500}>
                        {' '}
                        <span className="subtext">Pay</span> {formatCurrency(Number(orderDetails?.data?.order?.amount))}
                      </Typography>
                      <Typography fontSize="14px" color="n-grey3">
                        {orderDetails?.data?.order?.customerEmail}
                      </Typography>
                    </Flex>
                  </MobileHeader>

                  <PaymentMethods>
                    <Typography
                      fontSize="12px"
                      color="n-grey4"
                      fontWeight={500}
                      style={{ marginBottom: '15px', paddingLeft: '10px' }}
                    >
                      PAYMENT METHODS
                    </Typography>

                    {paymentMethodsOptions.map((option) => (
                      <PaymentMethodItem
                        key={option.id}
                        active={active === option.id ? true : false}
                        onClick={() => setActive(option.id)}
                      >
                        {active === option.id ? option.activeIcon : option.inActiveIcon}
                        <Typography
                          fontSize="14px"
                          color={`${active === option.id ? 'dark' : 'n-grey4'}`}
                          fontWeight={500}
                        >
                          {option.name}
                          <div style={{ fontSize: '10px', color: '#0B7D00', fontWeight: '500' }}>{option.subText}</div>
                        </Typography>{' '}
                        <br />
                      </PaymentMethodItem>
                    ))}
                  </PaymentMethods>

                  <div
                    style={{
                      marginLeft: '3px',
                      marginRight: '3px',
                      marginTop: '170px',
                      padding: '8px',
                      background: 'linear-gradient(to right, #FFF5CC, #FFFCF2)',
                      borderRadius: '8px',
                      fontSize: '12',
                      lineHeight: '1.5',
                      border: '1px solid #FFEB99',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Star />
                    Try Nomba app for fast and reliable payments!
                  </div>
                </LeftPane>

                <RightPane>
                  <OrderDetails>
                    <Flex flexDirection="column">
                      <Typography fontSize="12px" color="n-grey3">
                        {orderDetails?.data?.order?.customerEmail}
                      </Typography>
                      <Flex alignItems="center" style={{ gap: '4px' }}>
                        <Typography fontSize="14px" fontWeight={500}>
                          {' '}
                          <span className="subtext">Pay</span>{' '}
                          {formatCurrency(Number(orderDetails?.data?.order?.amount))}
                        </Typography>

                        {!orderDetails?.data?.order?.merchantPaysFees && (
                          <Flex alignItems="center" style={{ gap: '4px' }}>
                            <Typography fontSize="14px" color="n-grey3">
                              (+{formatCurrency(Number(orderDetails?.data?.order?.fixedCharge))} fee added)
                            </Typography>
                            <NIconWrapper
                              onMouseEnter={() => setShowCustomerFee(true)}
                              onMouseLeave={() => setShowCustomerFee(false)}
                              style={{ cursor: 'pointer' }}
                            >
                              <NIcons icon={IconsEnum.OUTLINE_HELP} />
                            </NIconWrapper>
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                    <CloseModalNew onClick={() => setShowConfirmModal(true)} style={{ cursor: 'pointer' }} />
                    {showConfirmModal && (
                      <ConfirmModal confirm={confirm} handleClose={handleClose} showModal={showConfirmModal} />
                    )}
                  </OrderDetails>

                  {showCustomerFee ? (
                    <CustomerFeeBreakdown>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Typography fontSize="14px" color="n-grey3">
                          Amount
                        </Typography>
                        <Typography fontSize="14px" color="n-dark">
                          {formatCurrency(Number(orderDetails?.data?.order?.orderAmount))}
                        </Typography>
                      </Flex>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Typography fontSize="14px" color="n-grey3">
                          Service charge
                        </Typography>
                        <Typography fontSize="14px" color="n-dark">
                          {formatCurrency(Number(orderDetails?.data?.order?.fixedCharge))}
                        </Typography>
                      </Flex>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Typography fontSize="14px" color="n-grey3">
                          Total amount
                        </Typography>
                        <Typography fontSize="14px" color="n-dark">
                          {formatCurrency(Number(orderDetails?.data?.order?.amount))}
                        </Typography>
                      </Flex>
                    </CustomerFeeBreakdown>
                  ) : null}
                  {active === 1 && (
                    <Transfer
                      changePaymentMethod={changePaymentMethod}
                      onMoveToNewScreen={onMoveToNewScreen}
                      orderDetails={orderDetails}
                      ktaResponse={ktaResponse}
                      setShowCustomModal={setShowModal}
                      customId={customId}
                    />
                  )}
                  {active === 2 && (
                    <Card
                      changePaymentMethod={changePaymentMethod}
                      onMoveToNewScreen={onMoveToNewScreen}
                      orderDetails={orderDetails}
                      setShowCustomModal={setShowModal}
                      customId={customId}
                      setActive={setActive}
                    />
                  )}
                  {active === 3 && (
                    <NombaQr
                      changePaymentMethod={changePaymentMethod}
                      onMoveToNewScreen={onMoveToNewScreen}
                      orderDetails={orderDetails}
                      setShowCustomModal={setShowModal}
                      customId={customId}
                    />
                  )}
                  {active === 4 && (
                    <USSDSelectBank
                      changePaymentMethod={changePaymentMethod}
                      onMoveToNewScreen={onMoveToNewScreen}
                      orderDetails={orderDetails}
                      ktaResponse={ktaResponse}
                      setShowCustomModal={setShowModal}
                      customId={customId}
                      fetchBankResponse={fetchBankResponse}
                    />
                  )}
                </RightPane>
              </PaymentWrapper>

              <MobilePaymentWrapper>
                <MobileHeader>
                  <MerchantLogo>
                    {orderDetails?.data?.order?.businessLogo ? (
                      <img
                        alt="business"
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          objectFit: 'cover',
                        }}
                        src={orderDetails?.data?.order?.businessLogo}
                      />
                    ) : (
                      <Avatar
                        size="medium"
                        initials={getInitials(
                          orderDetails?.data?.order?.businessName,
                          orderDetails?.data?.order?.businessEmail
                        )}
                        imgUrl={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                          orderDetails?.data?.order?.businessName ??
                            orderDetails?.data?.order?.businessEmail ??
                            'Unknown'
                        )}&background=FFF5CC&color=665200&rounded=true`}
                      />
                    )}
                  </MerchantLogo>
                  <Flex flexDirection="column">
                    <Typography fontSize="18px" fontWeight={500}>
                      {' '}
                      <span className="subtext">Pay</span> {formatCurrency(Number(orderDetails?.data?.order?.amount))}
                    </Typography>
                    <Typography fontSize="14px" color="n-grey3">
                      {orderDetails?.data?.order?.customerEmail}
                    </Typography>

                    {!orderDetails?.data?.order?.merchantPaysFees && (
                      <Flex alignItems="center" mt="10px" style={{ gap: '4px' }}>
                        <Typography fontSize="14px" color="n-grey3">
                          (+{formatCurrency(Number(orderDetails?.data?.order?.fixedCharge))}) fee added
                        </Typography>
                        <NIconWrapper
                          onMouseEnter={() => setShowCustomerFee(true)}
                          onMouseLeave={() => setShowCustomerFee(false)}
                          style={{ cursor: 'pointer' }}
                        >
                          <NIcons icon={IconsEnum.OUTLINE_HELP} />
                        </NIconWrapper>
                      </Flex>
                    )}
                  </Flex>
                </MobileHeader>

                {!orderDetails?.data?.order?.merchantPaysFees && showCustomerFee ? (
                  <CustomerFeeBreakdown>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Typography fontSize="14px" color="n-grey3">
                        Amount
                      </Typography>
                      <Typography fontSize="14px" color="n-dark">
                        {formatCurrency(Number(orderDetails?.data?.order?.orderAmount))}
                      </Typography>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Typography fontSize="14px" color="n-grey3">
                        Service charge
                      </Typography>
                      <Typography fontSize="14px" color="n-dark">
                        {formatCurrency(Number(orderDetails?.data?.order?.fixedCharge))}
                      </Typography>
                    </Flex>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Typography fontSize="14px" color="n-grey3">
                        Total amount
                      </Typography>
                      <Typography fontSize="14px" color="n-dark">
                        {formatCurrency(Number(orderDetails?.data?.order?.amount))}
                      </Typography>
                    </Flex>
                  </CustomerFeeBreakdown>
                ) : null}
                {!showMobile ? (
                  <Box mb={100}>
                    <MobilePaymentMethods>
                      <Box p="30px">
                        <Typography fontSize="18px" lineHeight="18px" color="n-grey5" fontWeight={500}>
                          Choose any of the payment method below to pay
                        </Typography>
                      </Box>
                      {paymentMethodsOptions.map((option) => (
                        <MobilePaymentMethod
                          key={option.id}
                          onClick={() => {
                            setActive(option.id);
                            setShowMobile(true);
                          }}
                          style={{ borderBottom: '1px solid #EEEEEE' }}
                        >
                          <Flex style={{ gap: '10px' }} alignItems="center">
                            {active === option.id ? option.activeIcon : option.inActiveIcon}
                            <Typography fontSize="16px" lineHeight="18px" color="n-grey5" fontWeight={500}>
                              {option.mobileName}
                              <div style={{ fontSize: '10px', color: '#0B7D00', fontWeight: '500' }}>
                                {option.subText}
                              </div>
                            </Typography>
                          </Flex>
                          <ChevronRight />
                        </MobilePaymentMethod>
                      ))}
                    </MobilePaymentMethods>

                    <Box display="flex" width="100%" justifyContent="center" mt="40px">
                      <Button
                        onClick={() => cancel()}
                        bg="#FFFFFF"
                        color="#121212"
                        variant="large"
                        width="80%"
                        height="60px"
                        className="cursor-pointer change-btn"
                      >
                        <ButtonText>Cancel Payment</ButtonText>
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <>
                    {active === 1 && (
                      <Transfer
                        changePaymentMethod={changePaymentMethod}
                        onMoveToNewScreen={onMoveToNewScreen}
                        orderDetails={orderDetails}
                        ktaResponse={ktaResponse}
                        setShowCustomModal={setShowModal}
                        customId={customId}
                      />
                    )}
                    {active === 2 && (
                      <Card
                        changePaymentMethod={changePaymentMethod}
                        onMoveToNewScreen={onMoveToNewScreen}
                        orderDetails={orderDetails}
                        setShowCustomModal={setShowModal}
                        customId={customId}
                        setActive={setActive}
                      />
                    )}
                    {active === 3 && (
                      <NombaQr
                        changePaymentMethod={changePaymentMethod}
                        onMoveToNewScreen={onMoveToNewScreen}
                        orderDetails={orderDetails}
                        setShowCustomModal={setShowModal}
                        customId={customId}
                      />
                    )}
                    {active === 4 && (
                      <USSDSelectBank
                        changePaymentMethod={changePaymentMethod}
                        onMoveToNewScreen={onMoveToNewScreen}
                        orderDetails={orderDetails}
                        ktaResponse={ktaResponse}
                        setShowCustomModal={setShowModal}
                        customId={customId}
                        fetchBankResponse={fetchBankResponse}
                      />
                    )}
                  </>
                )}

                <MobileFooter>
                  <span style={{ marginRight: '10px' }}>Secured by</span> <FooterLogoDark />
                </MobileFooter>
              </MobilePaymentWrapper>
            </>
          )}
        </ModalV2>
      )}
      {currentScreen === 'FailedResponse' && <FailedResponse orderDetails={orderDetails} error={errorMsg} />}
      {currentScreen === 'SuccessResponse' && (
        <SuccessResponse
          hasSavedCards={orderDetails?.data?.hasSavedCards}
          onMoveToNewScreen={onMoveToNewScreen}
          saveCard={isSaveCard}
          orderDetails={orderDetails?.data?.order}
          showCustomModal={setShowModal}
          customId={customId}
          redirectUrl={redirectUrl}
        />
      )}
      {currentScreen === 'PhoneSuccess' && <PhoneSuccess orderDetails={orderDetails} />}
    </>
  );
}
