import * as React from 'react';

const ErrorIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse cx="6.22525" cy="6.08447" rx="5.98575" ry="6" fill="#F24424" />
    <path
      d="M5.85199 4.84401V8.9678H6.59841V4.84401H5.85199ZM5.80859 4.07841H6.64181V3.19971H5.80859V4.07841Z"
      fill="white"
    />
  </svg>
);

export default ErrorIcon;
