import { useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import {
  CardNumberInput,
  CardInputWrapper,
  CardNumberWrapper,
  FormRow,
  FormWrapper,
  TransferDetails,
  CvvTooltip,
  UnderInputTextOption,
  MobileCardDetails,
  MobileFormWrapper,
  MobileCardInputWrapper,
  MobileCardNumberInput,
  MobileControl,
} from '../style';
import { Flex, Box, Button } from 'rebass';
import { Checkbox, Typography } from 'kudi-component-library';
import {
  CardDetailsType,
  SavedCard,
  formatCurrency,
  getCardType,
  handleCallbackUrl,
  ScreenTypes,
} from '../../../utils';
import { Orderdetails } from '../../../redux/slice';
import { ButtonText, CardInputLogo, ErrorText } from '../../styles';
import VisaCard from '../../../assets/visa';
import MasterCard from '../../../assets/mastercard';
import VerveCard from '../../../assets/vervecard';
import OtherCards from '../../../assets/othercards';
import CvvHelp from '../../../assets/cvv-help';
import CardPin from './CardPin';
import CardOtp from './CardOtp';
import ErrorCardIcon from '../../../assets/error-card';
import SavedCards from './SavedCards';
import PhoneOtp from './PhoneOtp';
import AtmCardActiveIcon from '../../../assets/card-active';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { verifyPhone } from '../../../redux/action';
import Spinner from '../../../assets/spinner';
import { checkoutTokenization } from '../../../utils/featureFlags';

interface CardProps {
  orderDetails: Orderdetails | null;
  onMoveToNewScreen: (newscreen: ScreenTypes, error: string, saveCard: boolean) => void;
  changePaymentMethod: () => void;
  customId?: string;
  setShowCustomModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setActive: React.Dispatch<React.SetStateAction<number>>;
}

export default function Card({ orderDetails, changePaymentMethod, onMoveToNewScreen, setActive }: CardProps) {
  const [cardNumberError, setCardNumberError] = useState<boolean>(false);
  const [cardNumber, setCardNumber] = useState<string>('');
  const [cardType, setCardType] = useState<string>('');
  const [dateError, setDateError] = useState<boolean>(false);
  const [expiry, setExpiry] = useState<string>('');
  const [cvvError, setCvvError] = useState<boolean>(false);
  const [cvv, setCvv] = useState<string>('');
  const [currentSubScreen, setCurrentSubScreen] = useState<ScreenTypes>('CardInput');
  const [cardDetails, setCardDetails] = useState<CardDetailsType | null>(null);
  const [otpMessage, setOtpMessage] = useState<string>('');
  const [saveCard, setSaveCard] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [otpMsg, setOtpMsg] = useState<string>('');
  const [otpErr, setOtpErr] = useState<string>('');
  const [cards, setCards] = useState<SavedCard[]>([]);
  const [transactionId, setTransactionId] = useState<string>('');

  const dispatch = useDispatch<AppDispatch>();

  const formatWithSpaces = (s: string): string => {
    return s.replace(/(\d{4})/g, '$1 ').trim();
  };

  const moveToNextField = (nextFieldId: string) => {
    const nextField = document.getElementById(nextFieldId);
    if (nextField) {
      nextField.focus();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/\D/g, '');
    const spacedValue = formatWithSpaces(rawValue);

    let maxLength = 20;
    const userCardType = getCardType(rawValue);

    setCardType(userCardType);
    if (userCardType === 'Visa' || userCardType === 'Mastercard') {
      maxLength = 16;
    } else if (userCardType === 'Verve') {
      maxLength = 19;
    }

    if (rawValue.length > maxLength) {
      setCardNumberError(true);
      setCardNumber(spacedValue);
      return;
    }

    if (cardNumberError && rawValue.length === 0) {
      setCardNumberError(false);
    }

    setCardNumberError(rawValue.length === 0 || userCardType === 'Unknown');
    setCardNumber(spacedValue);

    // Move to the next field when card number is complete
    if (rawValue.length === maxLength) {
      moveToNextField('expiryField');
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    const cursorPosition = e.target.selectionStart; // Get cursor position

    // If backspace is pressed and cursor is at the position just after "/", delete the "/"
    if (cursorPosition === 3 && value.length === 3 && value.charAt(2) === '/') {
      // Remove the "/"
      value = value.slice(0, 2);
    } else {
      // Replace any non-digit characters
      value = value.replace(/[^\d]/g, '');

      // Insert "/" after the second character
      if (value.length >= 2 && value.charAt(2) !== '/') {
        value = value.slice(0, 2) + '/' + value.slice(2);
      }
    }

    setExpiry(value);

    // Check if expiry date is complete and valid
    if (value.length === 5) {
      const [month, year] = value.split('/');
      const currentYear = new Date().getFullYear().toString().substr(-2);
      if (parseInt(month, 10) > 12 || parseInt(month, 10) < 1 || parseInt(year, 10) < Number(currentYear)) {
        setDateError(true);
      } else {
        setDateError(false);
        moveToNextField('cvvField');
      }
    }
  };

  const handleCvvInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    value = value.replace(/[^\d]/g, '');
    if (value.length > 4 || value.length <= 2) {
      setCvvError(true);
    } else {
      setCvvError(false);
    }
    setCvv(value);
  };

  const valiDated = () => {
    if (cardNumber === '' || cvv === '' || expiry === '' || dateError || cvvError || cardNumberError) {
      return false;
    }
    return true;
  };

  const handleUseSavedCard = async () => {
    try {
      if (!orderDetails) return;
      setLoading(true);
      const payload = {
        orderReference: orderDetails?.data?.order?.orderId,
      };
      const result = await dispatch(verifyPhone(payload));
      setLoading(false);
      setOtpErr('');
      if (result?.payload?.data?.success) {
        setOtpMsg(result?.payload?.data?.message);
        setCurrentSubScreen('PhoneOtp');
      } else {
        setOtpErr(result?.payload?.data?.message);
      }
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        setOtpErr(error.message);
      } else {
        setOtpErr('An unknown error occurred');
      }
    }
  };

  const handleCardTransfer = () => {
    if (orderDetails) {
      const [month, year] = expiry.split('/');
      const cardDetails = {
        cardCVV: Number(cvv),
        cardExpiryMonth: Number(month),
        cardExpiryYear: Number(year),
        cardNumber: cardNumber.replace(/\s+/g, ''),
      };
      setCardDetails(cardDetails);
      setCurrentSubScreen('CardPin');
    }
  };

  const cancel = () => {
    if (orderDetails) {
      handleCallbackUrl(
        orderDetails?.data?.order?.callbackUrl,
        orderDetails?.data?.order?.orderId,
        orderDetails?.data?.order?.orderReference
      );
    }
  };

  const handleSaveCard = () => {
    setSaveCard(!saveCard);
    localStorage.setItem('saveCard', JSON.stringify(!saveCard));
  };

  return (
    <FadeIn delay={100}>
      {currentSubScreen === 'CardInput' && (
        <FadeIn delay={100}>
          <TransferDetails style={{ marginTop: '30px' }}>
            <Typography color="n-dark" fontWeight={500}>
              Enter your card information for payment
            </Typography>

            <FormWrapper>
              <FormRow>
                <CardNumberWrapper>
                  <CardInputWrapper isError={cardNumberError}>
                    <Typography fontSize="12px" color="n-grey5">
                      Card number
                    </Typography>
                    <CardNumberInput
                      id="cardNumberField"
                      key="cardInput"
                      type="tel"
                      placeholder="0000 0000 0000 0000 0000"
                      value={cardNumber}
                      onChange={(e) => handleChange(e)}
                    />
                  </CardInputWrapper>
                  <CardInputLogo>
                    {cardType === 'Visa' ? (
                      <VisaCard />
                    ) : cardType === 'Mastercard' ? (
                      <MasterCard />
                    ) : cardType === 'Verve' ? (
                      <VerveCard />
                    ) : cardNumberError ? (
                      <ErrorCardIcon />
                    ) : (
                      <OtherCards />
                    )}
                  </CardInputLogo>
                </CardNumberWrapper>
                {cardNumberError && <ErrorText>Card number is invalid</ErrorText>}
              </FormRow>

              <FormRow style={{ marginBottom: '2px' }}>
                <Flex style={{ gap: '10px' }}>
                  <Box style={{ width: '100%' }}>
                    <CardInputWrapper isError={dateError} style={{ marginBottom: '10px' }}>
                      <Typography fontSize="12px" color="n-grey5">
                        Expiry date
                      </Typography>
                      <CardNumberInput
                        id="expiryField"
                        key="expiry"
                        type="text"
                        name="expiryFocused"
                        placeholder="MM/YY"
                        maxLength={5}
                        value={expiry}
                        onChange={handleInputChange}
                      />
                    </CardInputWrapper>
                    {dateError && <ErrorText>Invalid date</ErrorText>}
                  </Box>
                  <Box style={{ width: '100%' }}>
                    <CardNumberWrapper>
                      <CardInputWrapper isError={cvvError}>
                        <Typography fontSize="12px" color="n-grey5">
                          CVV
                        </Typography>
                        <CardNumberInput
                          id="cvvField"
                          key="cvv"
                          type="number"
                          name="cvvFocused"
                          placeholder="123"
                          value={cvv}
                          maxLength={4}
                          onChange={handleCvvInputChange}
                        />
                      </CardInputWrapper>
                      <CardInputLogo>
                        <Box className="help-container">
                          <Typography fontSize="14px" color="n-grey3" style={{ cursor: 'pointer' }}>
                            Help?
                          </Typography>
                          <CvvTooltip className="help-text">
                            <CvvHelp />
                            <Typography fontSize="12px" color="n-light">
                              Your CVV is the 3-digit number located on the back of your credit/debit card on the right
                              side of the white signature strip
                            </Typography>
                          </CvvTooltip>
                        </Box>
                      </CardInputLogo>
                    </CardNumberWrapper>
                    {cvvError && <ErrorText>Invalid cvv</ErrorText>}
                  </Box>
                </Flex>
              </FormRow>

              {checkoutTokenization.enabled && (
                <UnderInputTextOption bottom={20} style={{ width: '100%', gap: '10px' }}>
                  <Checkbox
                    name="agree"
                    checked={saveCard}
                    onChange={() => setSaveCard(!saveCard)}
                    width="18px"
                    height="18px"
                  >
                    {null}
                  </Checkbox>
                  <Typography color="n-grey4" fontSize="14px">
                    Save card for future use
                  </Typography>
                </UnderInputTextOption>
              )}

              {valiDated() ? (
                <Button
                  onClick={() => handleCardTransfer()}
                  textAlign="center"
                  mt="20px"
                  bg="#FFCC00"
                  color="#121212"
                  variant="large"
                  width="100%"
                  height="50px"
                  className="cursor-pointer"
                >
                  <ButtonText>Pay {formatCurrency(Number(orderDetails?.data?.order?.amount))}</ButtonText>
                </Button>
              ) : (
                <Button
                  disabled={true}
                  opacity="1"
                  mt="20px"
                  bg="#FFF5CC"
                  color="#ccc"
                  variant="large"
                  width="100%"
                  height="50px"
                  className="cursor-pointer"
                >
                  <Typography color="n-grey3">
                    Pay {formatCurrency(Number(orderDetails?.data?.order?.amount))}
                  </Typography>
                </Button>
              )}
              {checkoutTokenization.enabled && orderDetails?.data?.hasSavedCards && (
                <>
                  <Flex
                    mt="30px"
                    justifyContent="center"
                    className="cursor-pointer"
                    onClick={() => handleUseSavedCard()}
                  >
                    {loading ? (
                      <Spinner />
                    ) : (
                      <Typography color="n-dark" fontWeight={500}>
                        Use saved cards
                      </Typography>
                    )}
                  </Flex>
                  <Flex mt="16px" justifyContent="center">
                    <ErrorText>{otpErr}</ErrorText>
                  </Flex>
                </>
              )}
            </FormWrapper>
          </TransferDetails>

          <MobileCardDetails>
            <Flex justifyContent="space-between" alignItems="center">
              <Typography color="n-grey5" fontWeight={600}>
                Pay by Card
              </Typography>
              <AtmCardActiveIcon />
            </Flex>

            <MobileFormWrapper>
              <FormRow>
                <CardNumberWrapper>
                  <MobileCardInputWrapper isError={cardNumberError}>
                    <Typography fontSize="12px" color="n-grey5">
                      Card number
                    </Typography>
                    <MobileCardNumberInput
                      id="cardNumberField"
                      key="cardInput"
                      type="tel"
                      placeholder="0000 0000 0000 0000 0000"
                      value={cardNumber}
                      onChange={(e) => handleChange(e)}
                    />
                  </MobileCardInputWrapper>
                  <CardInputLogo>
                    {cardType === 'Visa' ? (
                      <VisaCard />
                    ) : cardType === 'Mastercard' ? (
                      <MasterCard />
                    ) : cardType === 'Verve' ? (
                      <VerveCard />
                    ) : cardNumberError ? (
                      <ErrorCardIcon />
                    ) : (
                      <OtherCards />
                    )}
                  </CardInputLogo>
                </CardNumberWrapper>
                {cardNumberError && <ErrorText>Card number is invalid</ErrorText>}
              </FormRow>

              <FormRow style={{ marginBottom: '2px' }}>
                <Flex style={{ gap: '10px' }}>
                  <Box>
                    <MobileCardInputWrapper isError={dateError} style={{ marginBottom: '10px' }}>
                      <Typography fontSize="12px" color="n-grey5">
                        Expiry date
                      </Typography>
                      <MobileCardNumberInput
                        id="expiryField"
                        key="expiry"
                        type="text"
                        name="expiryFocused"
                        placeholder="MM/YY"
                        maxLength={5}
                        value={expiry}
                        onChange={handleInputChange}
                      />
                    </MobileCardInputWrapper>
                    {dateError && <ErrorText>Invalid date</ErrorText>}
                  </Box>
                  <Box>
                    <CardNumberWrapper>
                      <MobileCardInputWrapper isError={cvvError}>
                        <Typography fontSize="12px" color="n-grey5">
                          CVV
                        </Typography>
                        <MobileCardNumberInput
                          id="cvvField"
                          key="cvv"
                          type="number"
                          name="cvvFocused"
                          placeholder="123"
                          value={cvv}
                          maxLength={4}
                          onChange={handleCvvInputChange}
                        />
                      </MobileCardInputWrapper>
                      <CardInputLogo>
                        <Box className="help-container">
                          <Typography fontSize="14px" color="n-grey3" style={{ cursor: 'pointer' }}>
                            Help?
                          </Typography>
                          <CvvTooltip className="help-text">
                            <CvvHelp />
                            <Typography fontSize="12px" color="n-light">
                              Your CVV is the 3-digit number located on the back of your credit/debit card on the right
                              side of the white signature strip
                            </Typography>
                          </CvvTooltip>
                        </Box>
                      </CardInputLogo>
                    </CardNumberWrapper>
                    {cvvError && <ErrorText>Invalid cvv</ErrorText>}
                  </Box>
                </Flex>
              </FormRow>

              {checkoutTokenization.enabled && (
                <UnderInputTextOption bottom={20} style={{ width: '100%', gap: '10px' }}>
                  <Checkbox
                    name="agree"
                    checked={saveCard}
                    onChange={() => handleSaveCard()}
                    width="18px"
                    height="18px"
                  >
                    {null}
                  </Checkbox>
                  <Typography color="n-grey4" fontSize="14px">
                    Save card for future use
                  </Typography>
                </UnderInputTextOption>
              )}

              {valiDated() ? (
                <Button
                  onClick={() => handleCardTransfer()}
                  textAlign="center"
                  mt="20px"
                  bg="#FFCC00"
                  color="#121212"
                  variant="large"
                  width="100%"
                  height="60px"
                  className="cursor-pointer"
                >
                  <ButtonText>Pay {formatCurrency(Number(orderDetails?.data?.order?.amount))}</ButtonText>
                </Button>
              ) : (
                <Button
                  disabled={true}
                  opacity="1"
                  mt="20px"
                  bg="#FFF5CC"
                  color="#ccc"
                  variant="large"
                  width="100%"
                  height="60px"
                  className="cursor-pointer"
                >
                  <Typography color="n-grey3">
                    Pay {formatCurrency(Number(orderDetails?.data?.order?.amount))}
                  </Typography>
                </Button>
              )}
              {checkoutTokenization.enabled && orderDetails?.data?.hasSavedCards && (
                <>
                  <Flex
                    mt="30px"
                    justifyContent="center"
                    className="cursor-pointer"
                    onClick={() => setCurrentSubScreen('PhoneOtp')}
                  >
                    {loading ? (
                      <Spinner />
                    ) : (
                      <Typography color="n-dark" fontWeight={500}>
                        Use saved cards
                      </Typography>
                    )}
                  </Flex>
                  <Flex mt="16px" justifyContent="center">
                    <ErrorText>{otpErr}</ErrorText>
                  </Flex>
                </>
              )}
            </MobileFormWrapper>
          </MobileCardDetails>
          <MobileControl>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="100%"
              mt="40px"
            >
              <Button
                onClick={() => changePaymentMethod()}
                mb="20px"
                bg="#FFFFFF"
                color="#121212"
                variant="large"
                width="80%"
                height="60px"
                className="cursor-pointer change-btn"
              >
                <ButtonText>Change payment method</ButtonText>
              </Button>
              <Typography onClick={() => cancel()} className="cursor-pointer" color="n-grey4" fontWeight={600}>
                Cancel payment
              </Typography>
            </Box>
          </MobileControl>
        </FadeIn>
      )}

      {currentSubScreen === 'SavedCards' && orderDetails?.data?.hasSavedCards && (
        <SavedCards
          setCards={setCards}
          cards={cards}
          onMoveToNewScreen={onMoveToNewScreen}
          setCurrentSubScreen={setCurrentSubScreen}
          orderDetails={orderDetails}
        />
      )}
      {currentSubScreen === 'PhoneOtp' && orderDetails?.data?.hasSavedCards && (
        <PhoneOtp
          setCards={setCards}
          setOtpMsg={setOtpMsg}
          otpMsg={otpMsg}
          setCurrentSubScreen={setCurrentSubScreen}
          orderDetails={orderDetails}
        />
      )}

      {currentSubScreen === 'CardPin' && cardDetails && orderDetails?.data?.order?.orderId && (
        <CardPin
          saveCard={saveCard}
          setTransactionId={setTransactionId}
          setOtpMessage={setOtpMessage}
          onMoveToNewScreen={onMoveToNewScreen}
          setCurrentSubScreen={setCurrentSubScreen}
          orderReference={orderDetails?.data?.order?.orderId}
          cardDetails={cardDetails}
          setActive={setActive}
        />
      )}
      {currentSubScreen === 'CardOtp' && cardDetails && orderDetails?.data?.order?.orderId && (
        <CardOtp
          saveCard={saveCard}
          onMoveToNewScreen={onMoveToNewScreen}
          transactionId={transactionId}
          otpMessage={otpMessage}
          setCurrentSubScreen={setCurrentSubScreen}
          orderReference={orderDetails?.data?.order?.orderId}
          setActive={setActive}
        />
      )}
    </FadeIn>
  );
}
