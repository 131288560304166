export const cardRegex = {
  Verve: /^((506(0|1))|(507(8|9))|(6500))/,
  electron: /^(4026|417500|4405|4508|4844|4913|4917)\d{10}$/,
  Maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
  Dankort: /^(5019)\d+$/,
  InterPayment: /^(636)\d+$/,
  UnionPay: /^(62|88)\d+$/,
  Visa: /^((4[0-9]))/,
  Mastercard: /^((5[1-5]))/,
  Amex: /^3[47][0-9]{13}$/,
  Diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
  JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
};
